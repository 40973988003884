<template>
  <div>
    <v-col cols="10" sm="8" class="pa-0 mt-10 py-10 mt-10 mx-auto">
      <div class="font-bold text-2xl sm:text-4xl mt-10 pt-10">
        {{ dataItem.title || "-" }}
      </div>
      <div class="text-sm py-4">{{ dataItem.date || "-" }}</div>
      <img
        :src="baseUrlImage + dataItem.image.file_path"
        @error="$event.target.src = 'https://admin.bpdlh.id/assets/empty.png'"
        class="rounded-md mt-8 img-detail" />
      <div class="white grid justify-items-center px-0 pt-5">
        <v-col cols="12" class="pa-0">
          <div
            class="font-bold text-base sm:text-xl my-5 text-left"
            v-if="bahasa">
            Deskripsi
          </div>
          <div class="font-bold text-base sm:text-xl my-5 text-left" v-else>
            Description
          </div>
          <div class="text-left mb-10" v-html="dataItem.desc"></div>
          <div
            v-if="pageName === 'report detail' && dataItem.url"
            class="text-left">
            <v-btn
              class="btn-primary-outlined font-bold text-capitalize"
              @click="redirect(dataItem.url)">
              <span v-if="bahasa">Lihat Detail</span>
              <span v-else>View Detail</span>
            </v-btn>
          </div>
          <div v-if="pageName === 'sector detail'">
            <div
              class="text-left text-black mb-0 py-6 text-base sm:text-2xl sm:py-8 font-bold">
              <span v-if="bahasa">Program</span>
              <span v-else>Programs</span>
            </div>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                @click="goToProgram('program-detail', '1')"
                class="cursor-pointer mb-3 text-left">
                <v-row>
                  <v-col cols="auto" class="pt-4">
                    <img src="@/assets/images/icon_program.svg" width="80%" />
                  </v-col>
                  <v-col cols="10">
                    <div class="font-bold text-xl py-0">REDD+</div>
                    <div class="text-sm py-3">14 Juni 2021</div>
                    <div class="font-lora text-base">
                      USD 103 juta (2020 - 2023)
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div
              class="text-left text-black mb-0 mt-3 py-6 text-base sm:text-2xl sm:py-8 font-bold">
              <span v-if="bahasa">Kisah & Blog</span>
              <span v-else>Stories & Blogs</span>
            </div>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="text-left pa-3 cursor-pointer"
                @click="goTo('story-detail', i)"
                v-for="i in 4"
                :key="i">
                <v-img
                  :src="require('@/assets/images/cover_.png')"
                  class="rounded align-center pa-5"
                  height="250">
                  <v-col
                    cols="12"
                    class="rounded-md border border-white-500 pa-5 bg-blur text-white">
                    <div class="text-sm" v-if="bahasa">Kisah</div>
                    <div class="text-sm" v-else>Stories</div>
                    <div class="text-lg sm:text-xl tracking-wide py-2">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed sit.
                    </div>
                    <v-btn
                      class="btn-primary-white mt-3 font-bold text-capitalize">
                      <span v-if="bahasa">Baca selengkapnya</span>
                      <span v-else>Read More</span>
                    </v-btn>
                  </v-col>
                </v-img>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </div>
    </v-col>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isLoad: false,
      dataItem: {},
      lastUpdated: "",
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlImage: process.env.VUE_APP_BASE_ASSET,
    };
  },
  watch: {
    page() {
      this.getDetail();
    },
    language() {
      this.getDetail();
    },
  },
  computed: {
    pageName() {
      let path = this.$route.name;
      let page = path.split("/").join("");
      return page.includes("-") ? page.split("-").join(" ") : page;
    },
    language() {
      return this.bahasa ? "ID" : "ENG";
    },
    urlApi() {
      let name = "";
      switch (this.pageName) {
        case "report detail":
          name = "/report";
          break;
        case "karir":
          name = "/career";
          break;
        case "career detail":
          name = "/career";
          break;
        case "procurement":
          name = "/procurement";
          break;
        case "procurement detail":
          name = "/procurement";
          break;
        case "pengadaan":
          name = "/procurement";
          break;
        case "privacy policy":
          name = "/privacy";
          break;
        case "kebijakan privasi":
          name = "/privacy";
          break;
        case "syarat & ketentuan":
          name = "/tnc";
          break;
        case "terms & conditions":
          name = "/tnc";
          break;
      }
      return name;
    },
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    redirect(val) {
      window.open(val, "_self");
    },
    goToProgram(link, param) {
      this.$router.push({
        name: link,
        params: {
          id: param,
        },
      });
    },
    async getDetail() {
      this.isLoad = true;
      let param = {
        lang: this.language,
        page: this.page,
      };
      await axios
        .get(this.baseUrl + this.urlApi + "/" + this.id, { params: param })
        .then((response) => {
          this.dataItem = response.data.data;
          this.lastUpdated = response.data.data.lastUpdate;
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoad = false));
    },
  },
};
</script>
